<template>
  <div class="gg-container">
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >订单详情</span
      >
      <div class="clm" style="line-height:3;padding:20px 0">
        <div>
          <span>订单号：{{ info.order.order_sn }}</span>
          <span style="margin-left:15%;"
            >用户ID：{{ info.order.member_id }}</span
          >
        </div>
        <div>
          <span>创建时间：{{ info.order.created_at }}</span>
          <span style="margin:0 15%;">成交时间：{{ info.order.pay_time }}</span>
          <span>订单状态：{{ info.order.order_status }}</span>
        </div>
      </div>
      <div>
        <el-button
          type="success"
          style="padding: 10px;"
          @click="handleShoppingDetail()"
        >
          <i class="" style="font-size: 13px" />
          <span style="font-size: 26px">查看物流详情</span>
        </el-button>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >商品信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.goods"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="goods_name"
            label="商品名称"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="goods_price"
            label="销售价"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="number"
            label="购买数量"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="total_money"
            label="小计"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="discount_amount"
            label="优惠金额"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >优惠信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.voucher"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="name"
            label="优惠名称"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="amount"
            label="优惠金额"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >支付信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.payment"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="total_amount"
            label="订单金额"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="earn_amount"
            label="预收金额"
            align="center"
            width="auto"
            v-if="info.payment.is_earn == 1"
          >
          </el-table-column>
          <el-table-column
            prop="wait_money"
            label="待收金额"
            align="center"
            width="auto"
            v-if="info.payment.is_earn == 1"
          >
          </el-table-column>
          <el-table-column
            prop="discount_money"
            label="优惠金额"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="way_name"
            label="支付渠道"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="pay_time"
            label="支付时间"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="pay_sn"
            label="流水号"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="earn_pay_status"
            label="定金状态"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="end_pay_status"
            label="尾款状态"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >收货人信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="[info.shr]"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="shr_name"
            label="收货人"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="shr_mobile"
            label="手机号"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="region_name"
            label="省市区"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="shr_address"
            label="收货地址"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >物流信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.express"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="express_company"
            label="快递公司"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="express_no"
            label="快递单号"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="发货时间"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >评价信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.comment"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="content"
            label="评价内容"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="stars"
            label="总体星级"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="评价时间"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      v-if="info.express.length > 0"
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleCloseLogisticsInfo"
    >
      <div class="required" style="margin-bottom: 10px"></div>
      <span>物流信息</span>
      <div
        style="display: flex;justify-content: space-between;margin-bottom: 20px"
      >
        <div>快递公司：{{ info.express[0].express_company }}</div>
        <div style="margin-right: 30px">
          快递单号：{{ info.express[0].express_no }}
        </div>
      </div>
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in info.express[0].express_logs"
          :key="index"
          size="large"
          placement="top"
          :color="activity.time && activity.time.length > 0 ? '#2362FB' : ''"
          :timestamp="activity.time"
        >
          {{ activity.status }}
        </el-timeline-item>
      </el-timeline>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 10px; margin-bottom: 15px"
      >
        <!--<el-button class="mgr-20" size="mini" @click="handleCloseLogisticsInfo">取 消</el-button>-->
        <el-button size="mini" type="primary" @click="handleCloseLogisticsInfo"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderInfo } from "@/newApi/order";
export default {
  name: "OrderDetail",
  created() {
    console.log(this.$route.query.id);
    var params = {
      id: this.$route.query.id,
    };
    this._getOrderDetail(params);
    // this._getAdminExpressInfo(params.id);
  },
  data() {
    return {
      info: {},
      peopleInfo: {},
      originOrder: this.$route.query.originOrder || "customer",
      listLoading: false,
      dialogVisible: false,
      tableDataBasicInfo: [],
      tableDataPersonInfo: [],
      tableDataGoodInfo: [],
      tableDataMoneyInfo: [],
      tableDataOptionInfo: [],
      activities: [], // 物流时间线
      expressInfo: [], // 物流信息
      expressCompany: "", // 快递公司
      expressNo: "", // 快递单号
      sync_status: ["无需同步", "待同步", "已同步", "同步失败"], //订单同步状态:0-无需同步(内部订单),1-待同步,2-已同步,3-同步失败
      order_type: ["", "处方订单", "商城订单", "客情订单", "三方商城订单"], // 订单类型(1=处方订单  2 商城订单  3 客情订单 4 三方商城订单）
      activeStep: 0,
    };
  },
  methods: {
    _getOrderDetail(params) {
      this.listLoading = true;
      getOrderInfo(params)
        .then((response) => {
          if (response.code == 200) {
            console.log("");
            var _data = response.data;
            this.info = _data;
            this.peopleInfo = this.info.shr;
          } else {
            this.$message({
              type: "error",
              message: response.msg,
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    handleShoppingDetail() {
      if(this.info.express.length == 0) {
        this.$message({
          type: "error",
          message: "暂无物流信息",
        });
        return;
      }
      console.log(111);
      this.dialogVisible = true;
    },
    handleCloseLogisticsInfo() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.row {
  display: flex;
}
.clm {
  display: flex;
  flex-direction: column;
}
.jsb {
  justify-content: space-between;
}
.alc {
  align-items: center;
}
.content {
  margin-bottom: 20px;
  padding: 10px 0;
}
</style>
